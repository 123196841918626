// Get the current language from the HTML lang attribute
let lang = document.documentElement.lang;
let locale;
let translations = {};
var isAtTop = true;

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
var isChrome = /chrome/i.test(navigator.userAgent);

window.addEventListener("load", function () {
  window.scrollTo({
    top: 0,
  });
  // Проверяем, находится ли скролл на самом верху страницы

  document.body.classList.add("no-scroll");
  const loader = document.getElementById("loader");
  const progressBar = document.getElementById("progress");

  setTimeout(function () {
    // Плавно скрываем фон загрузки
    loader.classList.add("hide");

    document.body.classList.remove("no-scroll");
    if (window.pageYOffset > 0) {
      // Если скролл не на самом верху, прокручиваем страницу вверх
      window.scrollTo({
        top: 0,
      });
    }
    addSVGCodeOnScroll(
      "client",
      "img/svg/lines_21anim.svg",
      "animation_client"
    );
    addSVGCodeOnScroll(
      "partners",
      "img/svg/lines_21anim.svg",
      "animation_partners"
    );
    $(window).on("scroll", function () {
      if (isAtTop) {
        var scrollTop = $(window).scrollTop();

        if (scrollTop !== 0) {
          isAtTop = false;
        }
      } else {
        handleScrollToElement("partners_block", 2, 13);
        handleScrollToElement("client_block", 6, 5);
      }
    });
  }, 3000);

  // Имитируем загрузку контента (замените этот код на реальную загрузку)
  let progress = 0;
  const interval = setInterval(function () {
    progress += 1;
    progressBar.style.width = progress + "%";

    if (progress >= 100) {
      clearInterval(interval);
    }
  }, 10);
});

document.addEventListener("DOMContentLoaded", () => {
  // Translate the page to the default locale
  setLocale(lang);
});

async function setLocale(newLocale) {
  if (newLocale === locale) return;
  const newTranslations = await fetchTranslationsFor(newLocale);
  locale = newLocale;
  $("html").attr("lang", locale);
  translations = newTranslations;
  translatePage();
}

async function fetchTranslationsFor(newLocale) {
  const response = await fetch(`/lang/${newLocale}.json`);
  return await response.json();
}

function translatePage() {
  document.querySelectorAll("[data-i18n-key]").forEach(translateElement);
}

function translateElement(element) {
  const key = element.getAttribute("data-i18n-key");
  const translation = translations[key];
  element.innerHTML = translation;
}

var html = document.documentElement;
var body = document.body;

var scroller = {
  target: document.querySelector("main"),
  ease: 0.05, // <= scroll speed
  endY: 0,
  y: 0,
  resizeRequest: 1,
  scrollRequest: 0,
};

var requestId = null;

TweenLite.set(scroller.target, {
  rotation: 0.01,
  force3D: true,
});

// window.addEventListener("load", onLoad);

function onLoad() {
  updateScroller();
  window.focus();
  window.addEventListener("resize", onResize);
  document.addEventListener("scroll", onScroll);
}

function updateScroller() {
  var resized = scroller.resizeRequest > 0;

  if (resized) {
    var height = scroller.target.clientHeight;
    body.style.height = height + "px";
    scroller.resizeRequest = 0;
  }

  var scrollY = window.pageYOffset || html.scrollTop || body.scrollTop || 0;

  scroller.endY = scrollY;
  scroller.y += (scrollY - scroller.y) * scroller.ease;

  if (Math.abs(scrollY - scroller.y) < 0.05 || resized) {
    scroller.y = scrollY;
    scroller.scrollRequest = 0;
  }

  TweenLite.set(scroller.target, {
    y: -scroller.y,
  });

  requestId =
    scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller) : null;
}

function onScroll() {
  scroller.scrollRequest++;
  if (!requestId) {
    requestId = requestAnimationFrame(updateScroller);
  }
}

function onResize() {
  scroller.resizeRequest++;
  if (!requestId) {
    requestId = requestAnimationFrame(updateScroller);
  }
}

function addSVGCodeOnScroll(classname, svgPath, targetElementId) {
  $(window).scroll(function () {
    var element = $("." + classname);
    var windowHeight = $(window).height();
    var scrollTop = $(window).scrollTop();

    var elementOffsetTop = element.offset().top;
    var elementHeight = element.outerHeight();
    var elementMiddle = elementOffsetTop;
    var $targetElement = $("." + targetElementId);
    var $svgElements = $targetElement.find("svg");
    if ($svgElements.length > 1) {
      // Удаляем дополнительные элементы SVG
      $targetElement.children().not(":first").remove();
    }
    if (
      !element.attr("data-isCodeAdded") &&
      elementMiddle >= scrollTop &&
      elementMiddle <= scrollTop + windowHeight &&
      $svgElements.length === 0
    ) {
      // Выполнить AJAX-запрос для получения содержимого SVG-файла

      $.ajax({
        url: svgPath,
        dataType: "text",
        success: function (data) {
          // Добавить содержимое SVG-файла в целевой элемент
          element.attr("data-isCodeAdded", true); // Установить data-атрибут, указывающий на добавление кода
          $("." + targetElementId).append(data);
        },
        error: function () {
          console.log("Ошибка загрузки SVG-файла");
        },
      });
    }
  });
}

$(document).ready(function ($) {
  function setMaxHeightStyle() {
    var items = $(".partners_block-item");
    var maxHeight = 0;

    // Находим максимальную высоту
    items.each(function () {
      var itemHeight = $(this).outerHeight();
      maxHeight = Math.max(maxHeight, itemHeight);
    });

    // Применяем стили с максимальной высотой
    items.css("height", maxHeight + "px");
  }

  // Вызываем функцию при загрузке страницы и изменении размеров окна на мобильных устройствах
  $(window).on("load resize", function () {
    if ($(window).width() <= 768) {
      // Примерная ширина для мобильных устройств, можете настроить под свои нужды
      setMaxHeightStyle();
    } else {
      $(".partners_block-item").css("height", ""); // Сбрасываем высоту на больших устройствах
    }
  });
  $(window).on("resize", function () {});
  checkSize();
  langSwitcher();
  // $("[data-i18n-switcher]").on("click", function () {
  //   var switcher = $(this).data("i18n-switcher");
  //   setLocale(switcher);
  // });

  $("#arrow_contact-link").on("click", function () {
    if (isTouchDevice()) {
      $(this).addClass("no-hover"); // Добавляем класс 'no-hover' при касании кнопки
      setTimeout(() => {
        $(this).removeClass("no-hover"); // Удаляем класс 'no-hover' через 100 мс
      }, 500);
    }

    $("html, body").animate(
      {
        scrollTop: $("#cooperation_id").offset().top,
      },
      500
    );
  });
  $("#logo-link").on("click", function (e) {
    e.preventDefault();
    if (isTouchDevice()) {
      $(this).addClass("no-hover"); // Добавляем класс 'no-hover' при касании кнопки
      setTimeout(() => {
        $(this).removeClass("no-hover"); // Удаляем класс 'no-hover' через 100 мс
      }, 500);
    }

    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      500
    );
  });
});

function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints;
}

function checkSize() {
  /*=============== SCROLL REVEAL ANIMATION ===============*/
  const sr = ScrollReveal({
    origin: "top",
    distance: "40px",
    duration: 2500,
    delay: 200,
    // reset: true
  });

  sr.reveal(`.client_title `, {
    origin: "top",
    distance: "60px",
  });
  sr.reveal(
    `.client_block-item, .company_block, h4, .partners_block-item, .legal_financial_control `,
    {
      interval: 300,
      origin: "top",
      distance: "30px",
    }
  );

  sr.reveal(`.trans_left, .right_text`, {
    origin: "left",
    delay: 1000,
    distance: "60px",
  });

  sr.reveal(`.point-img-text`, {
    origin: "left",
    duration: 1000,
    delay: 500,
  });

  sr.reveal(`h1`, {
    origin: "left",
  });

  if ($(window).width() > 550) {
    sr.reveal(`a h3, .social_link-item`, {
      origin: "right",
    });
  }

  sr.reveal(`.w-550 p`, {
    origin: "bottom",
  });
}

// Анимация Блока контактов
let trigger_block = {};

function handleScrollToElement(targetElementName, numRows, numCols) {
  targetElement = $(`.${targetElementName}`);
  if (!trigger_block[targetElementName] && targetElementName in trigger_block) {
    return;
  } else {
    trigger_block[targetElementName] = true;
  }
  var elementPosition = targetElement.offset().top;

  var windowHeight = $(window).height();

  var scrollTop = $(window).scrollTop();

  if (
    scrollTop + windowHeight >= elementPosition &&
    trigger_block[targetElementName]
  ) {
    trigger_block[targetElementName] = false;
    targetElement.find(`.${targetElementName}-item`).each(function (index) {
      var delay = 300 * (index + 1); // Задержка для каждого блока
      var currentElement = $(this);

      setTimeout(function () {
        currentElement.append(
          `<div class="${targetElementName}-item_bg"></div>`
        );
        animation_block(
          currentElement.find(`.${targetElementName}-item_bg`),
          numRows,
          numCols
        );
      }, delay);
    });
  }
}

function animation_block(element, numRows, numCols) {
  var delay = 50;

  var $grid = element;
  var cellWidth = $grid.width() / numCols;
  var cellHeight = $grid.height() / numRows;

  var $cells = [];

  for (var i = numRows - 1; i >= 0; i--) {
    for (var j = 0; j <= numCols; j++) {
      var $cell = $("<div class='cell'></div>")
        .appendTo($grid)
        .css({
          left: j * cellWidth,
          top: i * cellHeight,
          width: cellWidth,
          height: cellHeight,
          opacity: 0,
        })
        .data("row", i) // Добавляем атрибут данных "row" со значением i
        .data("col", j); // Добавляем атрибут данных "col" со значением j

      $cells.push($cell);
    }
  }

  var totalDelay = delay;
  const diagonalElements = findDiagonalElements(numRows, numCols);

  var completedAnimations = 0;
  var totalAnimations = diagonalElements.reduce(
    (accumulator, currentValue) => accumulator + currentValue.length,
    0
  );

  let queue_delay = 10;
  let persantage_step = 1;

  if (isSafari) {
    queue_delay = 20;
    persantage_step = 10;
  } else if (isChrome) {
    queue_delay = 10;
    persantage_step = 1;
  }

  for (let i = 0; i < diagonalElements.length; i++) {
    diagonalElements[i].forEach(({ element, row, col }) => {
      const $cell = $cells.find(function (e) {
        return e.data("row") + 1 === row && e.data("col") + 1 === col;
      });

      if ($cell) {
        $cell
          .delay(totalDelay)
          .animate({ opacity: 1 }, 100)
          .queue(function () {
            var $cell = $(this);
            var percentage = 0;
            var gradientInterval = setInterval(function () {
              if (percentage <= 101) {
                $cell.css(
                  "background",
                  "linear-gradient(45deg, #2828E0 " +
                    percentage +
                    "%, black 100%)"
                );
                percentage = percentage + persantage_step;
              } else {
                clearInterval(gradientInterval);
              }
            }, queue_delay);
            completedAnimations++;
            if (completedAnimations === totalAnimations) {
              // Все анимации завершены
              setTimeout(() => {
                $($grid).find(".cell").remove(); // Удаление всех блоков с классом "cell" из $grid

                $($grid).css("background", "#2828E0"); // Установка фонового стиля "blue" для $grid
              }, totalAnimations * 100);
            }
          })
          .promise()
          .then(function () {});
      }
    });
    totalDelay += delay;
  }
}

function findDiagonalElements(rows, cols) {
  const diagonalLength = rows + cols - 1; // Длина диагонали

  const elements = [];

  for (let i = 1; i <= diagonalLength; i++) {
    const rowStart = Math.max(1, i - cols + 1);
    const rowEnd = Math.min(i, rows);
    const colStart = Math.max(cols - i + 1, 1);
    const colEnd = Math.min(cols, cols + rows - i);

    const diagonalElements = [];

    for (let row = rowStart; row <= rowEnd; row++) {
      const col = colStart + (row - rowStart);
      const element = row + col - 1;
      diagonalElements.push({ element, row, col });
    }

    elements.push(diagonalElements);
  }

  return elements.reverse();
}

$(window).on("scroll", function () {
  handleScrollToElement("partners_block", 2, 13);
  handleScrollToElement("client_block", 6, 5);
});

// Смена языка

function langSwitcher() {
  var container = $(".language-container");
  var blocks = $(".language-block");
  var isSwitched = false;

  blocks.on("click", function () {
    var switcher = $(this).data("i18n-switcher");
    if (switcher === locale) {
      return;
    }
    setLocale(switcher);
    if (isSwitched) {
      blocks.eq(0).removeClass("front").addClass("back");
      blocks.eq(1).removeClass("back").addClass("front");
    } else {
      blocks.eq(1).removeClass("front").addClass("back");
      blocks.eq(0).removeClass("back").addClass("front");
    }
    isSwitched = !isSwitched;
  });
}
